import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Particles from "react-particles-js";
import AnchorLink from 'react-anchor-link-smooth-scroll';
import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  List,
  Menu,
  Responsive,
  Segment,
  Sidebar,
  Visibility,
} from 'semantic-ui-react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard } from '@fortawesome/free-regular-svg-icons';
import { faEnvelope, faFilePdf, faCamera, faProjectDiagram, faTools, faFileInvoice } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin, faGithub, faTwitter, faYoutube, faInstagram } from '@fortawesome/free-brands-svg-icons';
import Slick from "./Slider.js";
import resume from "./doc/Mark-Bosky_Full-Stack_Web-Engineer_Resume.pdf";
import resumeThumbnail from "./img/resume-thumbnail.jpg";
import markPortrait from "./img/mark-portrait-square.jpg";
import jsLogo from "./img/logos/javascript-logo.png";
import apacheLogo from "./img/logos/apache-logo.png";
import htmlLogo from "./img/logos/html5-logo.png";
import gitLogo from "./img/logos/git-logo.png";
import cssLogo from "./img/logos/css3-logo.png";
import photoshopLogo from "./img/logos/photoshop-logo.png";
import sketchLogo from "./img/logos/sketch-logo.png";
import awsLogo from "./img/logos/aws-logo.png";
import sassLogo from "./img/logos/sass-logo.png";
import wordpressLogo from "./img/logos/wordpress-logo.png";
import phpLogo from "./img/logos/php-logo.png";
import mysqlLogo from "./img/logos/mysql-logo.png";
import bootstrapLogo from "./img/logos/bootstrap-logo.png";
import analyticsLogo from "./img/logos/analytics-logo.png";
import angularLogo from "./img/logos/angular-logo.png";
import jqueryLogo from "./img/logos/jquery-logo.png";
import lessLogo from "./img/logos/less-logo.png";
import linuxLogo from "./img/logos/linux-logo.png";
import wiresharkLogo from "./img/logos/wireshark-logo.png";
import premiereLogo from "./img/logos/adobe-premiere-pro-logo.png";
import accessibilityLogo from "./img/logos/web-accessibility-logo.png";
import xmlLogo from "./img/logos/xml-logo.png";
import jsonLogo from "./img/logos/json-logo.png";
import fusionLogo from "./img/logos/lucidworks-fusion-logo.png";
import logo from "./logo.svg";

// Heads up!
// We using React Static to prerender our docs with server side rendering, this is a quite simple solution.
// For more advanced usage please check Responsive docs under the "Usage" section.
const getWidth = () => {
  const isSSR = typeof window === 'undefined'

  return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth
}

const particlesOptions = {
  particles: {
    number: {
      value: 80,
      density: {
        enable: true,
        value_area: 800
      }
    }
  }
};

/* eslint-disable react/no-multi-comp */
/* Heads up! HomepageHeading uses inline styling, however it's not the best practice. Use CSS or styled components for
 * such things.
 */
const HomepageHeading = ({ mobile }) => (
  <Container text>
  <a href='#maincontent' className='sr-only'>Skip to main content</a>
    <Header
      as='h1'
      content='Mark Bosky'
      inverted
      style={{
        fontSize: mobile ? '2em' : '4em',
        fontWeight: 'normal',
        marginBottom: 0,
        marginTop: mobile ? '1.5em' : '3em',
      }}
    />
    <Header
      as='h2'
      content='Full-Stack Developer &amp; Web Engineer'
      inverted
      style={{
        fontSize: mobile ? '1.5em' : '1.7em',
        fontWeight: 'normal',
        marginTop: mobile ? '0.5em' : '1.5em',
      }}
    />
    <AnchorLink href='#about' id="link-go" className="ui huge primary button margin-top-2">
     <Icon name='down arrow' />
    </AnchorLink>
    <Particles className="particles" params={particlesOptions} />
  </Container>

)

HomepageHeading.propTypes = {
  mobile: PropTypes.bool,
}


class DesktopContainer extends Component {
  state = {}

  hideFixedMenu = () => this.setState({ fixed: false })
  showFixedMenu = () => this.setState({ fixed: true })

  render() {
    const { children } = this.props
    const { fixed } = this.state

    return (
      <Responsive getWidth={getWidth} minWidth={Responsive.onlyTablet.minWidth}>
        <Visibility
          once={false}
          onBottomPassed={this.showFixedMenu}
          onBottomPassedReverse={this.hideFixedMenu}
        >
          <Segment
            inverted
            textAlign='center'
            style={{ minHeight: 700, padding: '1em 0em' }}
            vertical className="hero-segment"
          >
            <Menu
              fixed={fixed ? 'top' : null}
              inverted={!fixed}
              pointing={!fixed}
              secondary={!fixed}
              size='large'
            >
              <Container>
                <Menu.Item as='a'>
                  Home
                </Menu.Item>
                <Menu.Item><AnchorLink href='#about'>About</AnchorLink></Menu.Item>
                <Menu.Item><AnchorLink href='#projects'>Projects</AnchorLink></Menu.Item>
                <Menu.Item><AnchorLink href='#skills'>Skills</AnchorLink></Menu.Item>
                <Menu.Item><AnchorLink href='#resume'>Resume</AnchorLink></Menu.Item>
                <Menu.Item><AnchorLink href='#connect'>Connect</AnchorLink></Menu.Item>
                <Menu.Item position='right'>
                  <a href='https://www.linkedin.com/in/markbosky/' className="header-social" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faLinkedin} size="lg" aria-hidden="true" /><span className="sr-only">LinkedIn</span></a>
                  <a href='https://github.com/markbosky' className="header-social" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faGithub} size="lg"  aria-hidden="true" /><span className="sr-only">GitHub</span></a>
                  <a href='https://twitter.com/markbosky' className="header-social" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faTwitter} size="lg"  aria-hidden="true" /><span className="sr-only">Twitter</span></a>
                </Menu.Item>
              </Container>
            </Menu>
            <HomepageHeading />
          </Segment>
        </Visibility>

        {children}
      </Responsive>
    )
  }
}

DesktopContainer.propTypes = {
  children: PropTypes.node,
}

class MobileContainer extends Component {
  state = {}

  handleSidebarHide = () => this.setState({ sidebarOpened: false })

  handleToggle = () => this.setState({ sidebarOpened: true })

  render() {
    const { children } = this.props
    const { sidebarOpened } = this.state

    return (
      <Responsive
        as={Sidebar.Pushable}
        getWidth={getWidth}
        maxWidth={Responsive.onlyMobile.maxWidth}
      >
        <Sidebar
          as={Menu}
          animation='push'
          inverted
          onHide={this.handleSidebarHide}
          vertical
          visible={sidebarOpened}
        >
          <Menu.Item as='a'>
            Home
          </Menu.Item>
          <Menu.Item><AnchorLink href='#about'>About</AnchorLink></Menu.Item>
                <Menu.Item><AnchorLink href='#projects'>Projects</AnchorLink></Menu.Item>
                <Menu.Item><AnchorLink href='#skills'>Skills</AnchorLink></Menu.Item>
                <Menu.Item><AnchorLink href='#resume'>Resume</AnchorLink></Menu.Item>
                <Menu.Item><AnchorLink href='#connect'>Connect</AnchorLink></Menu.Item>
                <Menu.Item position='right'>
                  <a href='https://www.linkedin.com/in/markbosky/' className="header-social" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faLinkedin} size="lg" aria-hidden="true"/><span className="sr-only">LinkedIn</span></a>
                  <a href='https://github.com/markbosky' className="header-social" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faGithub} size="lg"  aria-hidden="true"/><span className="sr-only">Github</span></a>
                  <a href='https://twitter.com/markbosky' className="header-social" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faTwitter} size="lg"  aria-hidden="true"/><span className="sr-only">Twitter</span></a>
                </Menu.Item>
        </Sidebar>

        <Sidebar.Pusher dimmed={sidebarOpened}>
          <Segment
            inverted
            textAlign='center'
            style={{ minHeight: 350, padding: '1em 0em' }}
            vertical className="hero-segment"
          >
            <Container>
              <Menu inverted pointing secondary size='large'>
                <Menu.Item onClick={this.handleToggle}>
                  <Icon name='sidebar' />
                </Menu.Item>
                <Menu.Item position='right'>
                  <a href='https://www.linkedin.com/in/markbosky/' className="header-social" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faLinkedin} size="lg" aria-hidden="true" /><span className="sr-only">LinkedIn</span></a>
                  <a href='https://github.com/markbosky' className="header-social" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faGithub} size="lg"  aria-hidden="true" /><span className="sr-only">Github</span></a>
                  <a href='https://twitter.com/markbosky' className="header-social" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faTwitter} size="lg"  aria-hidden="true" /><span className="sr-only">Twitter</span></a>
                </Menu.Item>
              </Menu>
            </Container>
            <HomepageHeading mobile />
          </Segment>

          {children}
        </Sidebar.Pusher>
      </Responsive>
    )
  }
}

MobileContainer.propTypes = {
  children: PropTypes.node,
}

const ResponsiveContainer = ({ children }) => (
  <div>
    <DesktopContainer>{children}</DesktopContainer>
    <MobileContainer>{children}</MobileContainer>
  </div>
)

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
}

const HomepageLayout = () => (
  <ResponsiveContainer>
  <Segment id="maincontent" name="maincontent" style={{ padding: '4em 0em' }} vertical>
      <Container text>
        <Header as='h3' style={{ fontSize: '2em' }} id="about">
          <FontAwesomeIcon icon={faAddressCard} size="lg" aria-hidden="true" style={{ marginRight: '5px' }} /> About Myself
        </Header>
      </Container>
    </Segment>
    <Segment style={{ padding: '6em 0em' }} vertical className="about-me-segment">
      <Grid container stackable verticalAlign='middle'>
        <Grid.Row>
          <Grid.Column width={8}>
            <Header as='h3' style={{ fontSize: '2em' }} className="about-text">
              I'm a passionate and technically savvy full-stack web engineer with a keen interest in front-end and user-experience.
            </Header>
          </Grid.Column>
          <Grid.Column floated='right' width={6}>
            <Image bordered circular size='medium' className='markPortrait' src={markPortrait} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
    <Segment style={{ padding: '6em 0em'}} vertical>
      <Container text>
        <Header as='h3' style={{ fontSize: '2em' }} id="projects">
          <FontAwesomeIcon icon={faProjectDiagram} size="lg" aria-hidden="true" style={{ marginRight: '5px' }} /> Projects
        </Header>
      </Container>
    </Segment>
    <Segment style={{ padding: '6em 0em', backgroundColor: '#252839', color: '#fff' }} vertical>
        <Grid container stackable verticalAlign='middle'>
          <Grid.Row>
            <Grid.Column width={16}>
              < Slick />
            </Grid.Column>
          </Grid.Row>
        </Grid>
    </Segment>
    <Segment style={{ padding: '4em 0em' }} vertical>
      <Container text>
        <Header as='h3' style={{ fontSize: '2em' }} id="skills">
          <FontAwesomeIcon icon={faTools} size="lg" aria-hidden="true" style={{ marginRight: '5px' }} /> Skills &amp; Technologies
        </Header>
      </Container>
    </Segment>
    <Segment style={{ padding: '0em' }} vertical>
      <Grid celled='internally' columns='equal' stackable>
        <Grid.Row textAlign='center'>
          <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
            <Header as='h3' style={{ fontSize: '2em' }}>
              Front End
            </Header>
            <div className="skill-logo"><a href="https://html.spec.whatwg.org/multipage/" title="Official HMTL5 Spec" target="_blank" rel="noopener noreferrer" className="skill-logo-link"><span className="skill-logo-img"><img src={htmlLogo} alt="HTML5 Logo" /></span> <span className="skill-text">HTML5</span></a></div>
            <div className="skill-logo"><a href="https://www.w3.org/Style/CSS/Overview.en.html" title="Official W3C CSS Spec" target="_blank" rel="noopener noreferrer" className="skill-logo-link"><span className="skill-logo-img"><img src={cssLogo} alt="CSS3 Logo" /></span> <span className="skill-text">CSS3</span></a></div>
            <div className="skill-logo"><a href="https://helpx.adobe.com/photoshop/user-guide.html" title="Photoshop User Guide" target="_blank" rel="noopener noreferrer" className="skill-logo-link"><span className="skill-logo-img"><img src={photoshopLogo} alt="Photoshop Logo" /></span><span className="skill-text">Photoshop</span></a></div>
            <div className="skill-logo"><a href="https://www.sketch.com/docs/" title="Sketch Documentation" target="_blank" rel="noopener noreferrer" className="skill-logo-link"><span className="skill-logo-img"><img src={sketchLogo} alt="Sketch Logo" /></span> <span className="skill-text">Sketch</span></a></div>
            <div className="skill-logo"><a href="https://sass-lang.com/documentation" title="SCSS Website" target="_blank" rel="noopener noreferrer" className="skill-logo-link"><span className="skill-logo-img"><img src={sassLogo} alt="SASS Logo" /></span> <span className="skill-text">SASS/SCSS</span></a></div>
            <div className="skill-logo"><a href="http://lesscss.org/usage/" title="LESS Website" target="_blank" rel="noopener noreferrer" className="skill-logo-link"><span className="skill-logo-img"><img src={lessLogo} alt="LESS Logo" /></span> <span className="skill-text">LESS</span></a></div>
            <div className="skill-logo"><a href="https://getbootstrap.com/docs/4.4/getting-started/introduction/" title="Bootstrap Documentation" target="_blank" rel="noopener noreferrer" className="skill-logo-link"><span className="skill-logo-img"><img src={bootstrapLogo} alt="Bootstrap Framework Logo" /></span> <span className="skill-text">Bootstrap</span></a></div>
            <div className="skill-logo"><a href="https://developers.google.com/analytics" title="Google Analytics - Google Developers" target="_blank" rel="noopener noreferrer" className="skill-logo-link"><span className="skill-logo-img"><img src={analyticsLogo} alt="Google Analytics Logo" /></span> <span className="skill-text">Google Analytics</span></a></div>
            <div className="skill-logo"><a href="https://www.w3.org/WAI/standards-guidelines/wcag/" title="W3C - Web Accessibility Content Guidelines" target="_blank" rel="noopener noreferrer" className="skill-logo-link"><span className="skill-logo-img"><img src={accessibilityLogo} alt="W3C - WACG Logo" /></span> <span className="skill-text">W3C Web Accessibility</span></a></div>
            <div className="skill-logo"><a href="https://helpx.adobe.com/premiere-pro/user-guide.html" title="Adobe Premiere Pro User Guide" target="_blank" rel="noopener noreferrer" className="skill-logo-link"><span className="skill-logo-img"><img src={premiereLogo} alt="Adobe Premiere Pro Logo" /></span> <span className="skill-text">Premiere Pro</span></a></div>

          </Grid.Column>
          <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
            <Header as='h3' style={{ fontSize: '2em' }}>
              Full Stack
            </Header>
              <div className="skill-logo"><a href="https://developer.mozilla.org/en-US/docs/Web/JavaScript" title="MDN JavaScript Reference" target="_blank" rel="noopener noreferrer" className="skill-logo-link"><span className="skill-logo-img"><img src={jsLogo} alt="JavaScript Logo" /></span> <span className="skill-text">JavaScript</span></a></div>
              <div className="skill-logo"><a href="https://jquery.com/" title="jQuery Website" target="_blank" rel="noopener noreferrer" className="skill-logo-link"><span className="skill-logo-img"><img src={jqueryLogo} alt="Jquery Logo" /></span> <span className="skill-text">jQuery</span></a></div>
              <div className="skill-logo"><a href="https://www.php.net/manual/en/index.php" title="PHP Manual" target="_blank" rel="noopener noreferrer" className="skill-logo-link"><span className="skill-logo-img"><img src={phpLogo} alt="PHP Logo" /></span> <span className="skill-text">PHP</span></a></div>
              <div className="skill-logo"><a href="https://angularjs.org/" title="AngularJS Website" target="_blank" rel="noopener noreferrer" className="skill-logo-link"><span className="skill-logo-img"><img src={angularLogo} alt="AngularJS Logo" /></span> <span className="skill-text">AngularJS</span></a></div>
              <div className="skill-logo"><a href="https://codex.wordpress.org/" title="WordPress Codex" target="_blank" rel="noopener noreferrer" className="skill-logo-link"><span className="skill-logo-img"><img src={wordpressLogo} alt="WordPress Logo" /></span> <span className="skill-text">WordPress</span></a></div>
              <div className="skill-logo"><a href="https://git-scm.com/doc" title="Git Documentation" target="_blank" rel="noopener noreferrer" className="skill-logo-link"><span className="skill-logo-img"><img src={gitLogo} alt="Git Version Control Logo" /></span> <span className="skill-text">GIT/Version Control</span></a></div>
              <div className="skill-logo"><a href="https://dev.mysql.com/doc/" title="mySQL Documentation" target="_blank" rel="noopener noreferrer" className="skill-logo-link"><span className="skill-logo-img"><img src={mysqlLogo} alt="mySQL Logo" /></span> <span className="skill-text">MySQL</span></a></div>
              <div className="skill-logo"><a href="https://httpd.apache.org/" title="Apache Website" target="_blank" rel="noopener noreferrer" className="skill-logo-link"><span className="skill-logo-img"><img src={apacheLogo} alt="Apache Logo" /></span> <span className="skill-text">Apache</span></a></div>
              <div className="skill-logo"><a href="https://aws.amazon.com/" title="Amazon Web Services Website" target="_blank" rel="noopener noreferrer" className="skill-logo-link"><span className="skill-logo-img"><img src={awsLogo} alt="Amazon Web Services Logo" /></span> <span className="skill-text">Amazon Web Services</span></a></div>
              <div className="skill-logo"><a href="https://www.linux.org/" title="Linux Website" target="_blank" rel="noopener noreferrer" className="skill-logo-link"><span className="skill-logo-img"><img src={linuxLogo} alt="Linux Logo" /></span> <span className="skill-text">Linux</span></a></div>
              <div className="skill-logo"><a href="https://doc.lucidworks.com/fusion-server/4.2/" title="Lucidworks Fusion Documentation" target="_blank" rel="noopener noreferrer" className="skill-logo-link"><span className="skill-logo-img"><img src={fusionLogo} alt="Lucidworks Fusion Search Platform Logo" /></span> <span className="skill-text">Lucidworks Fusion</span></a></div>
              <div className="skill-logo"><a href="https://www.json.org/json-en.html" title="JSON Guide" target="_blank" rel="noopener noreferrer" className="skill-logo-link"><span className="skill-logo-img"><img src={jsonLogo} alt="JSON (JavaScript Object Notation) Logo" /></span> <span className="skill-text">JSON</span></a></div>
              <div className="skill-logo"><a href="https://www.w3.org/XML/" title="W3C XML Guide" target="_blank" rel="noopener noreferrer" className="skill-logo-link"><span className="skill-logo-img"><img src={xmlLogo} alt="XML (Extensible Markup Language) Logo" /></span> <span className="skill-text">XML</span></a></div>
              <div className="skill-logo"><a href="https://www.wireshark.org/" title="Wireshark Website" target="_blank" rel="noopener noreferrer" className="skill-logo-link"><span className="skill-logo-img"><img src={wiresharkLogo} alt="Wireshark Packet & Protocol Analyzer Logo" /></span> <span className="skill-text">Wireshark</span></a></div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
    <Segment style={{ padding: '4em 0em' }} vertical>
      <Container text>
        <Header as='h3' style={{ fontSize: '2em' }} id="resume">
          <FontAwesomeIcon icon={faFileInvoice} size="lg" aria-hidden="true" style={{ marginRight: '5px' }} /> Resume
        </Header>
      </Container>
    </Segment>
    <Segment style={{ padding: '6em 0em' }} vertical className="resume-segment">
      <Grid container stackable verticalAlign='middle'>
        <Grid.Row>
          <Grid.Column width={16}>
            <a href={resume} target="_blank" rel="noopener noreferrer"><img src={resumeThumbnail} alt="Resume Document Thumbnail" className="resume-document-thumbnail" /></a>
            <p>
              <Button as='a' size='large' href={resume} target="_blank" rel="noopener noreferrer" className="resume-dl-button">
                <FontAwesomeIcon icon={faFilePdf} size="lg" aria-hidden="true" /> Download Resume
              </Button>
            </p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
    <Segment inverted vertical style={{ margin: '5em 0em 0em', padding: '5em 0em', borderTop: '0.66em solid darkslategrey' }}>
      <Container textAlign='center'>
        <Grid divided inverted stackable>
          <Grid.Column width={8}>
            <Header inverted as='h4' content='Connect' id="connect" />
            <List link inverted>
              <List.Item as='a' href="https://github.com/markbosky" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faGithub} size="sm"  aria-hidden="true" /> Github</List.Item>
              <List.Item as='a' href="https://twitter.com/markbosky" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faTwitter} size="sm"  aria-hidden="true" /> Twitter</List.Item>
              <List.Item as='a' href="https://www.linkedin.com/in/markbosky/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faLinkedin} size="sm" aria-hidden="true" /> LinkedIn</List.Item>
              <List.Item as='a' href="https://www.instagram.com/markbosky/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faInstagram} size="sm" aria-hidden="true" /> Instagram</List.Item>
              <List.Item as='a' href="https://www.youtube.com/channel/UCOWYoBi_ildpIAKgf_48CvQ" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faYoutube} size="sm" aria-hidden="true" /> YouTube</List.Item>
              <List.Item as='a' href="https://unsplash.com/@markbosky" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faCamera} size="sm"  aria-hidden="true" /> Unsplash</List.Item>
            </List>
          </Grid.Column>
          <Grid.Column width={8}>
            <Header inverted as='h4' content='Please feel free to reach out!' />
            <p className="email-footer">
              <a href="mailto:mark.bosky@gmail.com"><FontAwesomeIcon icon={faEnvelope} size="lg" aria-hidden="true" /> mark.bosky@gmail.com</a>
            </p>
          </Grid.Column>
        </Grid>

        <Divider inverted section />
        <Image centered size='mini' src={logo} />
      </Container>
    </Segment>
  </ResponsiveContainer>
)
export default HomepageLayout