import React from 'react';
import ReactGA from 'react-ga';
import HomepageLayout from './Layout.js'
import './App.css';

ReactGA.initialize('UA-49431507-5');
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  return (
    <div className="App">
      <HomepageLayout />
    </div>
  );
}

export default App;

