import React, { Component } from "react";
import Slider from "react-slick";
//import assetsImg from "./img/thumbs/assets-mo-gov-150x150.png";
import capitolImg from "./img/screens/capitol-mo-gov.jpg";
import ltgovImg from "./img/screens/ltgov-mo-gov.jpg";
import assetsImg from "./img/screens/assets-mo-gov.jpg";
import spotlightImg from "./img/screens/spotlight-mo-gov.jpg";
import fultonrebuildImg from "./img/screens/fultonrebuild-mo-gov.jpg";
import eieraImg from "./img/screens/eiera-mo-gov.jpg";
import farmsafetyImg from "./img/screens/farmsafety-mo-gov.jpg";
import stormawareImg from "./img/screens/stormaware-mo-gov.jpg";
import showmestartupsImg from "./img/screens/showmestartups-mo-gov.jpg";
import moteachersImg from "./img/screens/moteachers-mo-gov.jpg";
import journeytocollegeImg from "./img/screens/journeytocollege-mo-gov.jpg";
import buymissouriImg from "./img/screens/buymissouri-net.jpg";
import decodebase64Img from "./img/screens/decodebase64-pw.jpg";
import searchappImg from "./img/screens/searchapp-mo-gov.jpg";
import mogovImg from "./img/screens/www-mo-gov.jpg";
import discountImg from "./img/screens/discount-mo-gov.jpg";
import missouribuysImg from "./img/screens/missouribuys-mo-gov.jpg";
import dpsImg from "./img/screens/dps-mo-gov.jpg";
import semaImg from "./img/screens/sema-dps-mo-gov.jpg";
import dfsImg from "./img/screens/dfs-dps-mo-gov.jpg";
import atcImg from "./img/screens/atc-dps-mo-gov.jpg";
import mcpImg from "./img/screens/mcp-dps-mo-gov.jpg";
import mvcImg from "./img/screens/mvc-dps-mo-gov.jpg";
import msbImg from "./img/screens/msb-dese-mo-gov.jpg";
import bccImg from "./img/screens/bcc-mo-gov.jpg";
import mdaImg from "./img/screens/mda-mo-gov.jpg";
import censusImg from "./img/screens/census-mo-gov.jpg";
import weddingImg from "./img/screens/the-boskys-com.jpg";
import moheartgalleryImg from "./img/screens/moheartgallery-org.jpg";
export default class MultipleItems extends Component {
  render() {
    const settings = {
      dots: true,
      arrows: false,
      lazyLoad: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3
    };
    return (
      <div>
        <Slider {...settings}>
          <div>
            <a href="https://assets.mo.gov/" target="_blank" rel="noopener noreferrer"><img src={assetsImg} alt="Assets Screenshot"/></a>
            <h4>WebTeam Assets</h4>
          </div>
          <div>
            <a href="https://searchapp.mo.gov/search-missouri/mdc" target="_blank" rel="noopener noreferrer"><img src={searchappImg} alt="Search Portal Screenshot"/></a>
            <h4>Enterprise Search Applications</h4>
          </div>
          <div>
            <a href="https://census.mo.gov/" target="_blank" rel="noopener noreferrer"><img src={censusImg} alt="Missouri 2020 Census website screenshot"/></a>
            <h4>2020 Census</h4>
          </div>
          <div>
            <a href="https://www.the-boskys.com" target="_blank" rel="noopener noreferrer"><img src={weddingImg} alt="Bosky wedding website screenshot"/></a>
            <h4>The Boskys: Wedding Website</h4>
          </div>
          <div>
            <a href="https://moheartgallery.org/" target="_blank" rel="noopener noreferrer"><img src={moheartgalleryImg} alt="Missouri Heart Gallery Homepage Screenshot"/></a>
            <h4>Missouri Heart Gallery</h4>
          </div>
          <div>
            <a href="https://journeytocollege.mo.gov/" target="_blank" rel="noopener noreferrer"><img src={journeytocollegeImg} alt="Journey to College Screenshot"/></a>
            <h4>Jourey to College Portal</h4>
          </div>
          <div>
            <a href="https://ltgov.mo.gov/" target="_blank" rel="noopener noreferrer"><img src={ltgovImg} alt="Ltgov.mo.gov Screenshot"/></a>
            <h4>Lieutenant Governor</h4>
          </div>
          <div>
            <a href="https://buymissouri.net/business-directory/" target="_blank" rel="noopener noreferrer"><img src={buymissouriImg} alt="BuyMissouri Screenshot"/></a>
            <h4>BuyMissouri</h4>
          </div>
          <div>
            <a href="https://decodebase64.pw/" target="_blank" rel="noopener noreferrer"><img src={decodebase64Img} alt="DecodeBase64 Tool Screenshot"/></a>
            <h4>Base64 Encoder/Decoder</h4>
          </div>
          <div>
            <a href="https://fultonrebuild.mo.gov/timeline.html" target="_blank" rel="noopener noreferrer"><img src={fultonrebuildImg} alt="Fulton Rebuild Screenshot"/></a>
            <h4>Fulton Hospital Rebuild Project</h4>
          </div>
          <div>
           <a href="https://stormaware.mo.gov/" target="_blank" rel="noopener noreferrer"><img src={stormawareImg} alt="StormAware Screenshot"/></a>
           <h4>StormAware</h4>
          </div>
          <div>
            <a href="https://www.mo.gov/" target="_blank" rel="noopener noreferrer"><img src={mogovImg} alt="Mo.Gov Screenshot"/></a>
            <h4>State of Missouri Portal</h4>
          </div>
          <div>
            <a href="https://moteachers.mo.gov/" target="_blank" rel="noopener noreferrer"><img src={moteachersImg} alt="MOTeachers Screenshot"/></a>
            <h4>Celebrating Missouri Teachers Blog</h4>
           </div>
          <div>
            <a href="https://showmestartups.mo.gov/" target="_blank" rel="noopener noreferrer"><img src={showmestartupsImg} alt="Show-Me Startups Screenshot"/></a>
            <h4>Show-Me Startups</h4>
          </div>
          <div>  
            <a href="https://capitol.mo.gov/" target="_blank" rel="noopener noreferrer"><img src={capitolImg} alt="Capitol Screenshot"/></a>
            <h4>Capitol Commission</h4>
          </div>
          <div>  
            <a href="https://eiera.mo.gov/" target="_blank" rel="noopener noreferrer"><img src={eieraImg} alt="EIERA Screenshot"/></a>
            <h4>Environmental Improvement and Energy Resources Authority</h4>
          </div>
          <div>
            <a href="https://farmsafety.mo.gov/" target="_blank" rel="noopener noreferrer"><img src={farmsafetyImg} alt="FarmSafety Screenshot"/></a>
            <h4>Farm Safety</h4>
          </div>
          <div>
            <a href="https://spotlight.mo.gov/" target="_blank" rel="noopener noreferrer"><img src={spotlightImg} alt="Spotlight Screenshot"/></a>
            <h4>Employee Spotlight</h4>
          </div>    
          <div>
            <a href="https://discount.mo.gov/" target="_blank" rel="noopener noreferrer"><img src={discountImg} alt="Discount Screenshot"/></a>
            <h4>Employee Discount Website</h4>
          </div>

          <div>
            <a href="https://dps.mo.gov/" target="_blank" rel="noopener noreferrer"><img src={dpsImg} alt="DPS Screenshot"/></a>
            <h4>Department of Public Safety</h4>
          </div>

          <div>
            <a href="https://sema.dps.mo.gov/" target="_blank" rel="noopener noreferrer"><img src={semaImg} alt="SEMA Screenshot"/></a>
            <h4>State Emergency Management Authority</h4>
          </div>

          <div>
            <a href="https://dfs.dps.mo.gov/" target="_blank" rel="noopener noreferrer"><img src={dfsImg} alt="DFS Screenshot"/></a>
            <h4>Division of Fire Safety</h4>
          </div>

          <div>
            <a href="https://atc.dps.mo.gov/" target="_blank" rel="noopener noreferrer"><img src={atcImg} alt="ATC Screenshot"/></a>
            <h4>Alcohol &amp; Tabacoo Control</h4>
          </div>

          <div>
            <a href="https://mvc.dps.mo.gov/" target="_blank" rel="noopener noreferrer"><img src={mvcImg} alt="MVC Screenshot"/></a>
            <h4>Missouri Veterans Commission</h4>
          </div>

          <div>
            <a href="https://msb.dese.mo.gov/" target="_blank" rel="noopener noreferrer"><img src={msbImg} alt="MSB Screenshot"/></a>
            <h4>Missouri School for the Blind</h4>
          </div>

          <div>
            <a href="https://agriculture.mo.gov/" target="_blank" rel="noopener noreferrer"><img src={mdaImg} alt="Agriculture Screenshot"/></a>
            <h4>Missouri Department of Agriculture</h4>
          </div>

          <div>
            <a href="https://missouribuys.mo.gov/" target="_blank" rel="noopener noreferrer"><img src={missouribuysImg} alt="MoBuys Screenshot"/></a>
            <h4>MissouriBuys Statewide eProcurement System</h4>
          </div>

          <div>
            <a href="https://mcp.dps.mo.gov/" target="_blank" rel="noopener noreferrer"><img src={mcpImg} alt="MCP Screenshot"/></a>
            <h4>Missouri Capitol Police</h4>
          </div>

          <div>
            <a href="https://bcc.mo.gov/" target="_blank" rel="noopener noreferrer"><img src={bccImg} alt="BCC Screenshot"/></a>
            <h4>Boonville Correctional Center</h4>
          </div>
        </Slider>
      </div>
    );
  }
}